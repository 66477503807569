import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import Canvas from './canvas/canvas'

const Animation = ({pathData=[], height=0, width=0, viewBox='0 0 0 0', backgroundColor='#000000', background, styles={}, svgStyles={}}) => {
    const Container = styled(`div`,{
        shouldForwardProp:prop=>
            prop !== 'styles'
    })(({styles})=>({
        ...styles
    }))
     return (
    <Container styles={styles}>
        { 
            <Canvas 
                height={height} 
                width={width} 
                viewBox={viewBox} 
                elements={pathData}
                backgroundColor={backgroundColor}
                background={background}
                styles={svgStyles}/>  
        } 
    </Container>
  )
}
export default Animation
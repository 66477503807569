import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from "react-helmet";
import {styled} from '@mui/material/styles'
import { Grid } from '@mui/material'
import { CssBaseline } from "@mui/material";

const Layout = ({pageTitle, pageDescription, pageImage, pageImageWidth, pageImageHeight, pageType, url, schema, children }) => {
    const Container = styled(Grid)({
        "@keyframes appear":{
            to:{
                opacity:1
            }
        },
            overflow:'hidden',
            opacity:0.01,
            animation:"appear .25s linear .25s forwards",
            width:'100vw',
            height:'100vh'
    })
    const data  = useStaticQuery(
        graphql`
            query{
                datoCmsSite {
                    faviconMetaTags {
                      tags
                    }
                    globalSeo {
                      fallbackSeo {
                        title
                        description
                      }
                    }
                  }
            }
        `
    )
    const tags = data?.datoCmsSite.faviconMetaTags.tags
    const { description } = data?.datoCmsSite?.globalSeo.fallbackSeo
    const renderedTags = tags?.map(
        (tag) =>
          ({
            link: (
              <link
                key={tag.attributes.rel + "-" + tag.attributes.sizes}
                type="image/x-icon"
                sizes={tag.attributes.sizes}
                rel={tag.attributes.rel}
                href={tag.attributes.href}
              />
            ),
            meta: (
              <meta
                key={tag.attributes.rel + "-" + tag.attributes.sizes}
                name={tag.attributes.name}
                content={tag.attributes.content}
              />
            ),
          }[tag.tagName])
      );
    return (
        <Container>
            <Helmet
                htmlAttributes={{
                    lang:'en'
                }}
                bodyAttributes={{
                    style:{width:'100vw',height:'100vh'}
                }}
            >
                <meta charset="utf-8" />
                <title>
                   {pageTitle || description}
                </title>
                <meta
                    name="description"
                    content={pageDescription || description}
                />
                <meta
                    name="image"
                    content={pageImage || "https://www.datocms-assets.com/53000/1651242325-fb_profile_pic.png"}
                />
                <meta itemprop="name" content="Lake of the Web Design" />
                <meta
                    itemprop="description"
                    content={description}
                />
                <meta
                    itemprop="image"
                    content="https://www.datocms-assets.com/53000/1651242325-fb_profile_pic.png"
                />
                <meta property="og:title" content={pageTitle || "Lake of the Web Design"} />
                <meta
                    property="og:description"
                    content={pageDescription || description}
                />
                <meta
                    property="og:image"
                    content={pageImage || "https://www.datocms-assets.com/53000/1651242325-fb_profile_pic.png"}
                />
                <meta
                    property="og:image:width"
                    content={pageImageWidth || '384'}
                />
                <meta
                    property="og:image:height"
                    content={pageImageHeight || '384'}
                />
                <meta property="og:url" content={`https://www.lotwebdesign.com${url || ''}`} />
                <meta property="og:site_name" content="Lake of the Web Design" />
                <meta property="og:locale" content="en_CA" />
                <meta property="og:type" content={pageType || 'website'} />
                <link rel="canonical" href={`https://www.lotwebdesign.com${url || ''}`} />
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
                {renderedTags}
                <script type="application/ld+json">
                    { schema
                        ?
                        schema
                        :
                        `
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "name": "Lake of the Web Design",
                        "image": "https://www.datocms-assets.com/53000/1651242325-fb_profile_pic.png",
                        "url": "https://www.lotwebdesign.com/",
                        "telephone": "8074645268",
                        "address": {
                            "@type": "PostalAddress",
                            "addressLocality": "Kenora",
                            "addressRegion": "ON",
                            "addressCountry": "CA"
                        }
                        }
                    `}
                </script>
                <script type="text/javascript">
                    {`
                    !function(f,b,e,v,n,t,s)
                    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                    n.queue=[];t=b.createElement(e);t.async=!0;
                    t.src=v;s=b.getElementsByTagName(e)[0];
                    s.parentNode.insertBefore(t,s)}(window, document,'script',
                    'https://connect.facebook.net/en_US/fbevents.js');
                    fbq('init', '2920107924940282');
                    fbq('track', 'PageView');
                `
                }</script>
                <script type="text/html">
                    {`
                    <img height="1" width="1" style="display:none"
                    src="https://www.facebook.com/tr?id=2920107924940282&ev=PageView&noscript=1"
                    />
                `}
                </script>
               </Helmet>
                <CssBaseline/>
            {children}
        </Container>
    )
}

export default Layout
import React from 'react'
import AnimatedPathTransform from '../animatedPathTransform/animatedPathTransform'
import AnimatedPathCSSChange from '../animatedPathCSSChange/animatedPathCSSChange'
import AnimatedPathDChange from '../animatedPathDChange/animatedPathDChange'
import { styled } from '@mui/material'

const AnimatedPath = (props) => { 
    const multipleAnimations = Array.isArray(props.type)
    const Path = styled('path')({
        ...props.styles,
        transformOrigin:props.transformOrigin || 'center',
        transformBox:'fill-box',
        fill:props.gradient ? `url(#${props.id}Gradient)`: props.color,
        stroke:props.stroke ? props.stroke : 'none',
        strokeWidth:props.strokeWidth ? props.strokeWidth : 0,
      })

    const AnimatedElement = (props) => {return(
        {
            translate:<AnimatedPathTransform {...props}/>,
            rotate:<AnimatedPathTransform {...props}/>,
            scale:<AnimatedPathTransform {...props}/>,
            skewX:<AnimatedPathTransform {...props}/>,
            css:<AnimatedPathCSSChange {...props}/>,
            d:<AnimatedPathDChange {...props}/>
        }[props.type] || null
    )}
    const Container = ({children}) => (
        props.group || props.noPath
        ?
        <>{children}</>
        :
        <Path
            id={props.id}
            d={props.d}
            filter={props.filter ? `url(#${props.filter.id})` : undefined }
        >
            {children}
        </Path>
    )
  return (
    <Container>
        {
            !props.animate
            ?
            <></>
            :
            multipleAnimations
            ?
            props.type.map((t, key)=>(
                <AnimatedElement 
                    id={`${props.id}${t}`} 
                    values={props.values[key]} 
                    keyTimes={props.keyTimes[key]}
                    dur={props.dur[key]}
                    delay={props.delay[key]}
                    type={t}
                    repeatCount={props.repeatCount[key]}
                    attributeName={props.attributeName?.[key] || props.attributeName}
                    attributeType={props.attributeType}
                    additive={props.additive}
                    animate={props.animate}
                    callback={props.callback?.[key]}
                    href={props.href}
                    filter={props.filter}
                />
            ))
            :
            <AnimatedElement 
                    id={`${props.id}${props.type}`} 
                    values={props.values}
                    keyTimes={props.keyTimes}
                    dur={props.dur}
                    delay={props.delay}
                    type={props.type}
                    repeatCount={props.repeatCount}
                    attributeName={props.attributeName}
                    attributeType={props.attributeType}
                    additive={props.additive}
                    animate={props.animate}
                    callback={props.callback}
                    href={props.href}
                    filter={props.filter}
                />
        }
    </Container>
    
  )
}

export default AnimatedPath
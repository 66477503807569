import React from 'react'
import loadable from '@loadable/component'
import { styled, useTheme } from '@mui/material/styles'
import Logo from '../logo/logo'
import WhyChooseLake from '../whyChooseLake/whyChooseLake'
import ButtonOrder from '../../contexts/buttonOrderContext'
import Menu from '../menu/menu'

const SectionModals = loadable(()=>import('../sectionModals/sectionModals'))

const LandingSection = ({background, videos, videoThumbnails, images, location}) => {

    const theme = useTheme()
    const Container = styled('div')({
      display:'flex',
      position:'relative',
      justifyContent:'center',
      width:'100vw',
      height:'100vh',
      backgroundColor:'black',
    })
    const BackGround = styled('div')({
      position:'absolute',
      top:0,
      left:0,
      width:'100%',
      height:'100%',
      backgroundColor:'white',
      background:`linear-gradient(180deg, ${theme.palette.gradients.dodgerBlue} 0%, ${theme.palette.gradients.lavenderBlush} 40%, ${theme.palette.gradients.lavenderBlush} 50%, ${theme.palette.gradients.zaffre} 50%, ${theme.palette.gradients.zaffre} 60%, ${theme.palette.gradients.frenchViolet} 90%)`,
      animation:'appear .25s linear 1s forwards',
      opacity:0
    })
    const MiddleLine = styled('div')({
      '@keyframes growLine':{
        from:{
          width:'0%',
        },
        to:{
          width:'100%'
        }
      },
      position:'absolute',
      top:'45%',
      left:0,
      height:'10%',
      width:'0%',
      background:`linear-gradient(90deg, ${theme.palette.gradients.lightGreen}, ${theme.palette.gradients.frostbite})`,
      animation:`growLine .5s linear .5s forwards`
    })
    const Absolute = styled('div')(()=>({
      position:'absolute',
      top:'25px',
      left:0,
      right:0,
      bottom:'250px',
      display:'initial',
      alignItems:'center',
      justifyContent:'center',
      color:'white',
      columnGap:'5%',
      zIndex:2,
      '& div':{
        backgroundColor:'transparent',
      },
      [theme.breakpoints.up('md')]:{
        display:'flex',
        columnGap:'5%',
        top:'100px'
      },
      [theme.breakpoints.up('lg')]:{
        display:'flex',
        alignItems:'center',
        columnGap:'50%',
        top:'15%'
      }
    }))
  return (
    <Container>
      <div style={{opacity:0.01, color:'black'}}>HELLO</div>
      <BackGround/>
      <MiddleLine/>
        <ButtonOrder>
          <Absolute>
              <Logo background={background} width="300px"/>
          </Absolute>
          <WhyChooseLake/>
            <Menu location={location?.hash}/>
            <SectionModals videos={videos} videoThumbnails={videoThumbnails} images={images}/>
          </ButtonOrder>
    </Container>

  )
}

export default LandingSection
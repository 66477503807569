import React, {useContext} from "react";
import { styled, useTheme } from "@mui/material";
import { ButtonOrderContext } from '../../contexts/buttonOrderContext'
import WhyLakeAnimation from "./whyLakeAnimation";

const WhyChooseLake = () => {
  const { setLandingModal } = useContext(ButtonOrderContext)
  const theme = useTheme();
  const Container = styled("div")({
    position: "fixed",
    bottom: "130px",
    width: "200px",
    height: "100px",
    animation: "appear .25s linear 1s forwards",
    opacity: 0,
    "::before": {
      content: '""',
      backgroundColor: `black`,
      backgroundImage: `linear-gradient(90deg, transparent 50%, ${theme.palette.gradients.frostbite} 100%)`,
      border: `2px solid ${theme.palette.gradients.frostbite}`,
      borderBottom: "none",
      borderLeft: "none",
      position: "absolute",
      top: "-10px",
      bottom: "-10px",
      right: "-10px",
      left: "-10px",
      zIndex: -1,
    },
    "::after": {
      content: '""',
      background:`linear-gradient(0deg,${theme.palette.gradients.lightGreen} 0%, transparent 50%)`,
      border: `2px solid ${theme.palette.gradients.lightGreen}`,
      borderRight: "none",
      borderTop: "none",
      position: "absolute",
      top: "-10px",
      bottom: "-10px",
      right: "-10px",
      left: "-10px",
      zIndex: -1,
    },
  });
  const WhyChooseButton = styled("button")({
    width: "100%",
    height: "100%",
    backgroundColor:theme.palette.gradients.zaffre,
    background:`linear-gradient(0deg, ${theme.palette.gradients.dodgerBlue} -75%, ${theme.palette.gradients.lavenderBlush} 75%)`,
    border: "none",
    padding: 0,
    cursor:'pointer',
    "::before": {
      content: '""',
      border: `2px solid ${theme.palette.gradients.frostbite}`,
      borderBottom: "none",
      borderLeft: "none",
      position: "absolute",
      top: "-2px",
      bottom: "-2px",
      right: "-2px",
      left: "-2px",
      zIndex: -1,
    },
    "::after": {
      content: '""',
      border: `2px solid ${theme.palette.gradients.lightGreen}`,
      borderRight: "none",
      borderTop: "none",
      position: "absolute",
      top: "-2px",
      bottom: "-2px",
      right: "-2px",
      left: "-2px",
      zIndex: -1,
    },
  });
  return (
    <Container>
      <WhyChooseButton aria-label="Why Lake of the Web Design?" onClick={setLandingModal}>
        <WhyLakeAnimation/>
      </WhyChooseButton>
    </Container>
  );
};

export default WhyChooseLake;

import React, { useState, createContext } from 'react'

const ButtonOrderContext = createContext({
    order:{
        home: {
            start: 0,
            end: 0,
          },
          services: {
            start: 0,
            end: 0,
          },
          contact: {
            start: 0,
            end: 0,
          },
          videos: {
            start: 0,
            end: 0,
          },
    },
    updateOrder:()=>{},
    openLandingModal:false,
    setLandingModal:()=>{}
})

const ButtonOrder = ({children}) => {
    const [ state, setState ] = useState({
        order:{
            home: {
                start: 0,
                end: 0,
              },
              services: {
                start: 0,
                end: 1,
              },
              contact: {
                start: 0,
                end: 2,
              },
              videos: {
                start: 0,
                end: 3,
              },
        },
        openLandingModal:false
    })
    const updateOrder = (name) => {
        const buttonOrder = state.order
        const openTarget = Object.entries(buttonOrder).find(
            (button) => button[1].end === 0
          )[0];
          const otherButtons = Object.entries(buttonOrder)
            .filter((button) => button[0] !== name && button[0] !== openTarget)
            .map((button) => button[0]);
          setState({
            ...state,
            order:{
                [name]: {
                    start: buttonOrder[name].end,
                    end: 0,
                    },
                    [openTarget]: {
                    start: 0,
                    end: buttonOrder[name].end,
                    },
                    [otherButtons[0]]: {
                    start: buttonOrder[otherButtons[0]].end,
                    end: buttonOrder[otherButtons[0]].end,
                    },
                    [otherButtons[1]]: {
                    start: buttonOrder[otherButtons[1]].end,
                    end: buttonOrder[otherButtons[1]].end,
                },
            },
            openLandingModal:false
          });
    }
    const setLandingModal = () => {
      setState({
        ...state,
        openLandingModal:!state.openLandingModal
      })
    }
    return (
        <ButtonOrderContext.Provider value={{...state, updateOrder:updateOrder, setLandingModal:setLandingModal}}>
            {children}
        </ButtonOrderContext.Provider>
    )
}

export default ButtonOrder
export {ButtonOrderContext}
import React from 'react'

const AnimatedPathDChange = ({id, values, keyTimes, dur, delay=0, repeatCount, additive }) => {
    return(
        <animate
         id={`${id}Animate`}
         attributeName='d'
         attributeType='XML' 
         values={values}
         keyTimes={keyTimes}
         calcMode='linear'
         dur={`${dur}s`}
         begin={parseInt(repeatCount) === 0  ? delay : delay ? `${delay}s;${id}Animate.end+${delay}s` : `0s`}
         fill="freeze"
         repeatCount={repeatCount || 0}
         additive={additive || "replace"}
         />)
}

export default AnimatedPathDChange
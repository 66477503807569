import React from 'react'
import AnimatedPathTransform from '../animatedPathTransform/animatedPathTransform'
import AnimatedPathCSSChange from '../animatedPathCSSChange/animatedPathCSSChange'
import AnimatedPathDChange from '../animatedPathDChange/animatedPathDChange'
import { styled } from '@mui/material'

const AnimatedRect = (props) => { 
    const multipleAnimations = Array.isArray(props.type)
    const Rect = styled('rect')({
        ...props.styles,
        transformOrigin:'center',
        transformBox:'fill-box',
        fill:props.gradient ? `url(#${props.id}Gradient)`: props.color,
        width:props.width,
        height:props.height,
        x:props.x,
        y:props.y,
        stroke:props.stroke ? props.stroke : 'none',
        strokeWidth:props.strokeWidth ? props.strokeWidth : 0,
      })

    const AnimatedElement = (props) => {return(
        {
            translate:<AnimatedPathTransform {...props}/>,
            rotate:<AnimatedPathTransform {...props}/>,
            scale:<AnimatedPathTransform {...props}/>,
            css:<AnimatedPathCSSChange {...props}/>,
            d:<AnimatedPathDChange {...props}/>
        }[props.type] || null
    )}
    const Container = ({children}) => (
        props.group
        ?
        <>{children}</>
        :
        <Rect
            id={`${props.id}`}
            filter={props.filter ? `url(#${props.filter.id})` : undefined }
        >
            {children}
        </Rect>
    )
  return (
    <Container>
        {
            !props.animate
            ?
            <></>
            :
            multipleAnimations
            ?
            props.type.map((t, key)=>(
                <AnimatedElement 
                    id={`${props.id}${t}`} 
                    values={props.values[key]} 
                    keyTimes={props.keyTimes[key]}
                    dur={props.dur[key]}
                    delay={props.delay[key]}
                    type={t}
                    repeatCount={props.repeatCount[key]}
                    attributeName={props.attributeName?.[key] || props.attributeName}
                    additive={props.additive}
                    animate={props.animate}
                    callback={props.callback?.[key]}
                />
            ))
            :
            <AnimatedElement 
                    id={`${props.id}${props.type}`} 
                    values={props.values}
                    keyTimes={props.keyTimes}
                    dur={props.dur}
                    delay={props.delay}
                    type={props.type}
                    repeatCount={props.repeatCount}
                    attributeName={props.attributeName}
                    additive={props.additive}
                    animate={props.animate}
                    callback={props.callback}
                />
        }
    </Container>
    
  )
}

export default AnimatedRect
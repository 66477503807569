import React from 'react'
import { styled } from '@mui/material'
import AnimatedPath from '../../animatedPath/animatedPath'
import AnimatedRect from '../../animatedRect/animatedRect'

const Canvas = ({width, height, viewBox, elements, backgroundColor, background, svgStyles={}}) => {
  const Svg = styled('svg')({
    width:width,
    height:height,
    backgroundColor:backgroundColor,
    background:background,
    ...svgStyles
  })
  const G = styled('g',{
    shouldForwardProp:(prop)=>
      prop !== 'opacity' ||
      prop !== 'transformOrigin'
  })(({opacity,filter,transformOrigin})=>({
    transformOrigin:transformOrigin || "center",
    transformBox:'fill-box',
    opacity:opacity,
    filter:filter
  }))
  const extractGradients = (elements) => { 
    return elements?.filter((element)=>(
      element.gradient || (element.group )
    ))
    .map((element)=>{
      const gradientSplit = element.gradient?.split(';')
      const colors = gradientSplit?.filter((item)=>item.includes('#') || item.includes('rgb'))
      const times = gradientSplit?.filter((item)=>item.includes('%'))
      const rotation = gradientSplit?.[gradientSplit.length - 1]
      return(
      element.group
      ?
      extractGradients(element.paths)
      :
      {id:element.id, colors:colors, times:times, rotation:rotation}
    )}).flat() || []
  }
  const gradients = extractGradients(elements)
  const extractPatterns = (elements) => {
    const patternArr = []
    elements?.filter((element)=>(
      element.pattern || element.group
    ))
    .forEach((element)=>{
      if(element.pattern)patternArr.push({...element.pattern})
      if(element.group)patternArr.push(extractPatterns(element.paths))
    }
    )
    return patternArr.flat()
  }
  const patterns = extractPatterns(elements)
  const extractFilters = (elements) => {
    return elements?.filter((element)=>(
      element.filter || element.group
    ))
    .map((element)=>(
      element.group
      ?
      element.filter
      ?
      extractFilters(element.paths).concat(...[element.filter])
      :
      extractFilters(element.paths)
      :
      {
        ...element.filter
      }
    ))
    .flat()
  }
  const filters = extractFilters(elements)
  const outputElements = (elements, animate) => {
    return elements?.map((element)=>{
      element.animate = (animate || element.animate)
      return(
      !element.group //not a group
      ?
      element.container === 'rect' //not a group and a rect container
      ?
      <AnimatedRect {...element}/> //rect
      : // not a group and not a rect container
      <AnimatedPath {...element}/>//path
      :// is a group
      (
        <>
          <G id={element.id} {...element.styles} transformOrigin={element.transformOrigin}>
            {
              element.container === 'rect'
              ?
              <AnimatedRect {...element}/>
              :
              <AnimatedPath {...element}/>
            }
            {
                outputElements(element.paths, element.animate)
            }
          </G>
        </>
      )
    )})
  }

  return (
    <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox || `0 0 ${width} ${height}}`} 
    >
      <defs>
        {
          patterns?.length && patterns.map((pattern)=>(
            <pattern 
              id={pattern?.id || ``} 
              x={pattern?.x || 0} y={pattern?.y || 0} 
              width={pattern?.width} 
              height={pattern?.height} 
              viewBox={pattern?.viewBox}
              patternTransform={pattern?.transform}
              patternUnits="userSpaceOnUse">
              {
                pattern.elements?.map((element)=>(
                  {
                    rect:(
                      <rect
                      width={element.width}
                      height={element.height}
                      fill={element.color}
                      x={element.x || 0}
                      y={element.y || 0}
                      stroke={element.stroke || 'none'}
                      strokeWidth={element.strokeWidth || '0'}
                    />),
                    image:(
                      <image
                        height={element.image?.height}
                        width={element.image?.width}
                        x={element.image?.x}
                        y={element.image?.y}
                        href={element.image?.href}
                      />
                    )
                  }[element.type] || <></>
                ))
              }
            </pattern>
          ))
        }
        {
          filters?.length && filters.map((filter)=>(
            <>
              <filter id={filter.id}>
                {
                  {
                    blur:<feGaussianBlur id={`fe${filter.id}`} stdDeviation={filter.stdDeviation}/>,
                    shadow:<feDropShadow id={`fe${filter.id}`} stdDeviation={filter.stdDeviation} floodColor={filter.color} dx={filter.dx} dy={filter.dy}/>
                  }[filter.type]
                }
                {
                  filter.animate && outputElements([filter.animate])
                }
              </filter>
            </>
          ))
          
        }
        {
          gradients?.length &&
              gradients.map((gradient)=>(
                <linearGradient id={`${gradient.id}Gradient`} gradientTransform={`rotate(${gradient.rotation})`}>
                  {
                    gradient.colors.map((color,key)=>(
                        <stop 
                          offset={gradient.times.length ? gradient.times[key] : `${(100/(gradient.colors.length-1))*key}%`}
                          stopColor={color}
                          />
                    ))
                  }
                </linearGradient>
              ))  
        }
        </defs>
        
        {
          outputElements(elements)
        }
    </Svg>
  )
}

export default Canvas
import {useEffect, useState} from 'react'
import { useTheme } from '@mui/material/styles'

const IsMobile = (theme) => {
    const [_isMobile, setIsMobile ] = useState(undefined)
    useEffect(()=>{
        if(typeof window !== undefined){
            setIsMobile(window?.matchMedia(`only screen and (max-width: ${theme.breakpoints.values.md}px)`).matches)
        }
        return _isMobile
    },[])
    return _isMobile
}
const IsMobileLandscape = (theme) => {
    const [isLandscape, setIsLandscape] = useState(undefined)
    useEffect(()=>{
        if(typeof window !== undefined){
            const checkForLandscape = () => (
                window.innerHeight < theme.breakpoints.values.md
                &&
                window.innerHeight < window.innerWidth / 1.5
                && 
                window.matchMedia("(orientation: landscape)").matches
            )
            const handleResize = () => {
                setIsLandscape(checkForLandscape())
            }
            window.addEventListener('resize', handleResize)
            setIsLandscape(checkForLandscape())
            return window.removeEventListener('resize', handleResize)
        }
    },[])
    return isLandscape 
}
const useIsMobile = () => {
    const theme = useTheme()
    return(
    {
        isMobile:IsMobile(theme),
        isMobileLandscape:IsMobileLandscape(theme)
    }
    )
}
export default useIsMobile
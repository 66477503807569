import React from 'react'

const AnimatedPathCSSChange = ({id, href, attributeName, attributeType, values, keyTimes, dur, delay=0, repeatCount, additive }) => { 
  return(
      <animate
       id={`${id}Animation`}
       href={href}
       attributeName={attributeName}
       attributeType={attributeType || 'CSS'}
       values={values}
       keyTimes={keyTimes}
       calcMode='linear'
       dur={`${dur}s`}
       begin={parseInt(repeatCount) === 0  ? delay : delay ? `${delay}s;${id}Animation.end+${delay}s` : `0s`} 
       fill="freeze"
       repeatCount={repeatCount || 0}
       additive={additive || "replace"}
       />)
}

export default AnimatedPathCSSChange
import React, { useEffect, useState, useRef, useContext } from "react";
import { Grid } from "@mui/material";
import { useTheme, styled } from "@mui/system";
import useIsMobile from '../../hooks/useIsMobile'
import { ButtonOrderContext } from '../../contexts/buttonOrderContext'
import DesktopIcon from "../desktopIcon/desktopIcon";

const Menu = () => {
const { isMobileLandscape } = useIsMobile()
const { order, updateOrder } = useContext(ButtonOrderContext)
const buttonOrder = order
const theme = useTheme();
const [ iconSize, setIconSize ] = useState(0)
const [ iconConfig, setIconConfig ] = useState({
    size:0,
    offset:0
})
const firstRender = useRef(true);
const [ resized, setResized ] = useState(false)
const _resized = useRef(false);
  const MenuContainer = styled(Grid)({
    backgroundColor: "black",
    padding: "0px 30px",
    "& p": {
      color: theme.palette.gradients.lightGreen,
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.gradients.lightGreen,
    },
  });
  const ButtonsContainer = styled('div')({
    backgroundColor:theme.palette.gradients.frenchViolet,
    position:'fixed',
    display:'flex',
    flexDirection:isMobileLandscape ? 'column' : 'row',
    bottom:0,
    left:0,
    right:0,
    zIndex:50
  })
  const onIconClicked = (name) => {
    order[name].end !== 0 && updateOrder(name);
  };
  const handleResize = () => {
    _resized.current = !_resized.current
    setResized(_resized.current)
  }
  useEffect(() => { 
    const desktopIcon = document?.querySelector('#desktopIcon')
    if(firstRender.current) {
      const { width } = desktopIcon.getBoundingClientRect()
      firstRender.current = false
      setIconConfig({
        size:isMobileLandscape ? window.innerHeight / 4 : width,
        offset:(
            isMobileLandscape || width < 100
            ?
            0
            :
            (window?.innerWidth - (width * 4)) / 2
        )
      })
      setIconSize(isMobileLandscape ? window?.innerHeight / 4 : width)
      if(typeof window !== undefined){
        window.addEventListener('resize', handleResize)
      }
      return;
    }
  },[firstRender, iconSize]);
  return (
    <MenuContainer>
        <ButtonsContainer>
        <DesktopIcon
          id="desktopIcon"
          name="home"
          start={buttonOrder.home.start}
          end={buttonOrder.home.end}
          onClick={onIconClicked}
          size={iconConfig.size}
          offset={iconConfig.offset}
          ariaLabel='Home Screen'
        />
        <DesktopIcon
          name="services"
          start={buttonOrder.services.start}
          end={buttonOrder.services.end}
          onClick={onIconClicked}
          size={iconConfig.size}
          offset={iconConfig.offset}
          ariaLabel='Services Modal'
          ariaHasPopUp='dialog'
        />
        <DesktopIcon
          name="contact"
          start={buttonOrder.contact.start}
          end={buttonOrder.contact.end}
          onClick={onIconClicked}
          size={iconConfig.size}
          offset={iconConfig.offset}
          ariaLabel='Contact Modal'
          ariaHasPopUp='dialog'
        />
        <DesktopIcon
          name="videos"
          start={buttonOrder.videos.start}
          end={buttonOrder.videos.end}
          onClick={onIconClicked}
          size={iconConfig.size}
          offset={iconConfig.offset}
          ariaLabel='Videos Modal'
          ariaHasPopUp='dialog'
        />
      </ButtonsContainer>
    </MenuContainer>
  )
}

export default Menu